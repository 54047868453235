import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout
    sermonSrc="https://www.youtube.com/embed/yxeFjMcKMAU"
    notesSrc="http://bible.com/events/7135848"
    notesText="Sermon Notes"
    bibleGroupSrc="https://www.docdroid.net/EFMkT9R/bible-group-homework-3-1415.pdf"
    bibleGroupText="Bible Group Homework"
  >
    <SEO title="Fear Not - Finding Courage in an Anxious World" />
  </Layout>
)

export default SermonPost
